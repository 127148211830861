.jumbotron {
  border-radius: 0;
  &.front-page {
    padding: 0;
    background: url('../images/tree6.jpg') center center fixed;
    color: #f9f9f9;
    margin-bottom: 0;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    h1, p {
      text-shadow: 0 0 5px rgba(#000, 1);
    }
    .jumbotron-inner {
      padding: 6rem 0;
    }
  }
  .jumbotron-inner {
    padding: 4rem 0;
    background-color: rgba(#222, .7);
  }
}