body {
  font-size: 16px;
}

.text-xs {
  font-size: $font-size-xs;
}

.es-category-item__all {
  color: #777;

  &.active {
    color: #000;
  }
}

.webform-component-fixed-value {
  @extend .text-muted;
}

.webform-component-fixed-value::after {
  content: ""; /* This is necessary for the pseudo element to work. */
  display: block; /* This will put the pseudo element on its own line. */
  border-bottom: 20px solid transparent;
}
