.entity-tripalentity {
  @extend .d-flex;
  @extend .flex-sm-nowrap;
  @extend .align-content-stretch;
  @include media-breakpoint-down(md) {
    @include flex-direction(column);

    .ds-right {
      width: 100%;
    }
  }

  .ds-right {
    @include flex(1);
  }

  .ds-left,
  .ds-right {
    float: none !important;
  }

  .ds-left {
    margin-top: 10px;
    margin-right: $spacer;
    width: auto;
    min-width: 200px;

    @include media-breakpoint-down(md) {
      margin-right: 0;
    }

    > .field {
      border-radius: $border-radius;
      border: 1px solid rgba(#000, .1);
    }
  }

  .tripal_pane-toc-list-item-link {
    position: relative;
    padding: .75rem 40px .75rem .75rem;
    display: block;
    color: $gray-600;
    border-left: 3px transparent solid;
    border-bottom: 1px solid rgba(#000, .1);
    &:hover,
    &:active,
    &.active {
      text-decoration: none;
      color: theme-color(primary);
      border-left-color: theme-color();
    }

    &.active {
      &:before {
        font-family: FontAwesome;
        display: block;
        position: absolute;
        right: 10px;
        top: 50%;
        margin-top: -11px;
        content: '\f054';
      }
    }
  }

  .tripal_pane {
    position: relative;
    padding: 0;
    box-shadow: 0 0 1px rgba(#000, .25), 0 2px 4px rgba(#000, .125);
    border: 0;
    margin: 0 0 $spacer 0;
    border-radius: $border-radius;
    color: #444;

    table {
      tr:first-of-type,
      tbody > tr:first-of-type,
      thead > tr:first-of-type {
        > td,
        > th {
          border-top: 0;
        }
      }
    }
  }

  .field-group-format-title {
    text-shadow: none;
    border: 0;
    padding: .5rem;
    margin: 0;
    border-bottom: 1px solid rgba(#000, .05);
    color: #000;
    font-weight: bold;
    text-transform: uppercase;
    background-color: $gray-100;
    font-size: .9rem;
    + div > .field {
      padding: .5rem;
    }
  }

  .tripal_pane-fieldset-buttons {
    display: none;
  }
}
