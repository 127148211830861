$color-grey: #818181;

.btn-primary.disabled, .btn-primary:disabled {
  cursor: no-drop; 
}

.arsiko-logo {
  .image {
    background: url('../images/logo.svg') no-repeat; 
    height: 56px;
    width: 257px;
    display: block;
  }
} 

// Переопрделяем дизайны на странице (контент)
.section .container {
  .btn-primary {
    background: #6A3EA3;
    color: white; 
    height: 70px;
  }
  .btn {
    border: 0;
    border-radius: 0;
    font-family: $font-header;
    font-weight: bold;
    font-size: 22px;
  }
}

.main-header {
  background: $color-main;
  color: $color-white;
  min-height: 120px;
  border-top: 0;
}

// Меню
.main-header {
  .nav-item a {
    text-transform: uppercase;
    font-family: $font-header;    
    font-size: 18px;
  }
}

.contact-info {
  font-family: $font-header;
  margin-left: 50px;
  @media (max-width: 576px) {
    margin-left: 0;
  }

  .phone {
    font-size: 24px;
    font-weight: bold;
  }
  .whatsapp, .email, .label {
    font-size: 14px;
    font-weight: bold;
  }
  .email a {
    color: $color-white;
  }
  .label {
    font-weight: normal;
  }
}

.forms-list-homepage {
  .node-webform {
    display: none;
  }
}

.services-list-thumbnail {
   .node-service {
    cursor: pointer;
    position: relative;
  }
  .field-name-title {
    position: absolute;
    text-align: center;
    bottom: 0;
    background: rgba(45, 0, 101, 0.60);   
    width: 100%;
    max-width: 262px;
    height: 75px; 
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .servicetitle {
    font-family: $font-header;
    font-size: 18px;
    font-weight: bold;
    color: $color-white;
    text-transform: uppercase;
    margin: 0;
  }
  img {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25); // TODO - надо бы вынести из изображения выше. Пока это сложно, так как изображение меньше родительского контейнера
  }
  img:hover {
    border: 2px solid #451385;
  }
  .node {
    @media (max-width: 768px) {
      left: 50%;
      transform: translate(-25%, 0);
    }  
  }
}

.view-services-list {
 
  .field-name-field-form-id {
    display: none;
  }

  .view-content .row .views-row:last-child {
    margin: 0 auto;
  }
}

.webform-client-form {

  & > div { //wrapper вокруг формы
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .form-item {
    margin-bottom: 20px;
  }

  .pricetitle {
    color: #6A3EA3;
    font-family: $font-header;
    font-weight: bold;
    font-size: 24px;
  }

  .form-select {
    width: 360px;
    height: 50px;
    border: 0;
    font-size: 18px;
    background: #EFEEF2 url('../images/select-arrow.svg') 95% center no-repeat;
    text-align-last:center;
    -webkit-appearance: none; 
    appearance: none
  }

  .form-select:hover {
    cursor: pointer;
    background: #E9E5FB url('../images/select-arrow.svg') 95% center no-repeat;
  }  
  
  .webform-component-fieldset {
    border: 0 !important;    
  }
  
  .webform-component--itog {
    padding: 0 !important;
    text-align: center;    
  }

  .webform-component--itog .fieldset-wrapper {
    background: #EFEEF2;
  }

  .fullprice, 
  .oldprice {
    font-family: $font-header;
    font-size: 36px;
  }

  .oldprice {
    display: block;
    font-size: 30px;
    text-decoration: line-through;
    color: $color-grey;
  }

  .btn-primary {
    width: 100%;
  }

  .form-file {
    max-width: 360px;
    height: 50px;
    background: #EFEEF2;
    border: 0;
    border-radius: 0;
    padding-top: 12px;
  }

  .form-managed-file .form-submit {
    display: none;
  }

  .webform-component-file .description {
    display: none;
  }

  .name input, .mailphone input{
    width: 360px;
    height: 50px;
    border-radius: 0;
    border: 0;
    background: #EFEEF2;

    &:hover {
      background: #E9E5FB;
    }
  }

  /**
   * Стили для сложных чекбоксов
   */
  .form-type-checkbox {
    display: block;
    position: relative;
    padding-left: 65px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 18px;
    height: 50px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .form-checkbox { 
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .checkmark {
    position: absolute;
    top: 0px;
    left: 0;
    height: 50px;
    width: 50px;
    background-color: #eee;
    border: 5px solid #EFEEF2;
    background-color: white;
  }

  .form-type-checkbox label {
    cursor: pointer;
    margin-top: 10px;
  }

  .form-type-checkbox:hover input + label .checkmark { 
    background-color: #e6e6e6;
  }

  .form-type-checkbox input:checked + label .checkmark {
    // background-color: #2196F3;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .form-type-checkbox input:checked + label .checkmark:after {
    display: block;
  }

  .form-type-checkbox .checkmark:after {
    left: 10px;
    top: 10px;
    width: 20px;
    height: 20px;
    background: #2196F3;
  }
  /*
   * Конец стилей для чекбоксов
   */
}

.webform-confirmation + .links {
  display: none;
}

.webformservicetitle {
  margin-bottom: 50px;
}

/**
 * Аккордион CKeditor
 */
.ckeditor-accordion-container {

  & > dl dt > a {
    background-color: #218838 !important;
  }

  & > dl {
    border: 1px solid #218838 !important;
  }

  & > dl dd {
    margin-top: 15px;
  }
}

/**
 * RSCSS Helpers
 *
 */
._center {
  text-align: center;
}

._nolink {
  color: inherit;

  &:hover {
    color: inherit;
    text-decoration: underline;
  }
}