// Main
body {
  background: #2a2730;
  font-size: $font-size-root;
}

#main {
  background-color: #fff;
}

.secondary-footer {
  padding: 20px 0;
  background: #2a2730;
  &,
  a {
    color: lighten(#2a2730, 70%);
  }

  a {
    font-weight: bold;
  }
}

.section {
  background: #fff;
  padding: 30px 0;
}

.section-sm {
  padding: 15px 0;
}

.section-header {
  display: inline-block;
  margin-bottom: 40px;
  position: relative;

  &,
  > a {
    color: $body-color;
  }

  > a {
    &:hover {
      text-decoration: none;
      color: #999;
    }
  }

  &:after {
    display: block;
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 10px;
    content: ' ';
    height: 3px;
    background: theme-color(success);
  }

  &:not(:first-of-type) {
    margin-top: 1.5rem;
  }

  .sidebar & {
    display: block;
    font-size: 1.4rem;
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid $card-border-color;
    &:after {
      display: none;
    }
  }
}

.page-header {
  background: darken(theme-color(success), 7.5%);
  color: #eee;

  h1,
  a {
    color: #fefefe;
  }
  a {
    font-weight: normal;
  }
  h1 {
    margin-bottom: 0;
  }
}

/*.form-wrapper {
  //padding: 0!important;
}*/

#tripal-pane-close-button {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.tabledrag-handle .handle {
  margin-right: 10px;
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &:before {
    content: "\f047";
  }
}

.form-group label {
  font-weight: bold;
}

.card {
  border: 0;
  box-shadow: 0 0 1px rgba(#000, .25), 0 2px 4px rgba(#000, .125);
}

.help-button-block {
  background: #fff;
  color: $gray-800;
  position: fixed;
  z-index: 1000;
  bottom: 0;
  right: 30px;
  width: 120px;
  box-shadow: -1px -1px 4px rgba(0, 0, 0, .2);
  // transition: width .2s linear;

  &,
  .help-button-trigger {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  &.open {
    width: 230px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .2);
    .help-button-trigger {
      background-color: $info;
    }
  }
}

.help-button-trigger {
  padding: 0.5rem 0.75rem;
  background-color: $info;
  display: block;
  width: 100%;
  color: #fff;
  text-align: center;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.125);
  box-shadow: 0 0 2px rgba(0, 0, 0, .125);

  &:hover {
    color: #fff;
    text-decoration: none;
    background: darken($info, 4%);
  }
}

.help-button-content {
  padding: .75rem;
  background-color: $gray-100;
  max-height: 60vh;
  overflow-y: auto;
  display: none;

  .help-node-title {
    color: $gray-700;
  }

  .help-tab-title {
    color: $gray-600;
  }
}
