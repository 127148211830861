.pager {
  @extend .pagination;

  > li {
    @extend .page-item;
    > a {
      @extend .page-link;
    }
    &.pager-current,
    &.pager-ellipsis {
      @extend .page-link;
      cursor: default;
      &:hover {
        background: #fff;
      }
      &,
      &:hover {
        color: #444;
      }
    }

    &.first {
      &,
      > a {
        margin-left: 0;
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
      }
    }

    &.last {
      &,
      > a {
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
      }
    }
  }
}