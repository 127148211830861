.submitted {
  @extend .text-muted;
  margin-bottom: $spacer;
}

.field-type-image {
  margin: $spacer 0;
  img {
    @extend .img-fluid;
  }
}

blockquote {
  padding: 1rem 2rem;
  border-left: 4px solid $gray-400;
  font-size: $font-size-base - .05;
  background: $gray-100;
  > p:last-of-type {
    margin-bottom: 0;
  }
}
