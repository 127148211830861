.es-cross-site-search-form {
  padding: 0 !important;
  border-width: 0 !important;

  > .fieldset-wrapper {
    padding: 0;
  }

  .form-group {
    float: left;
    margin-right: 5px;
  }

  select {
    @extend .form-control;
  }
}

.form-group {
  .form-control + .input-group-btn {
    .btn {
      border-top-right-radius: $border-radius !important;
      border-bottom-right-radius: $border-radius !important;
      margin-right: 0 !important;
    }
  }
}

.btn {
  cursor: pointer;
}

.btn-outline-secondary {
  border-color: #fff;
  color: #fff;
}

.btn-secondary {
  background-color: #fff;
  color: #888;
  border-color: $gray-500;

  &:hover {
    background-color: $gray-200;
    color: #666;
  }
}

input[type="radio"],
input[type="checkbox"] {
  margin-right: 5px;

  + label {
    margin-bottom: 0 !important;
    font-weight: normal;
  }
}

.form-radios,
.form-checkboxes {
  .form-group {
    margin-bottom: 5px;
  }
}

form label {
  font-weight: $font-weight-bold;
}

.form-required {
  color: theme-color(danger);
}

.container-inline {
  .form-group,
  .form-item {
    display: inline-block;
  }
}
